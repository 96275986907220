/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
// @import '~simple-line-icons/css/simple-line-icons.css';
// @import '~weather-icons/css/weather-icons.css';
// @import '~weather-icons/css/weather-icons-wind.css';

@import '~loaders.css/loaders.css';

@import '~ngx-toastr/toastr.css';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-fresh.css';

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';
// @import '~summernote/dist/summernote.css';

// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/list/main.css';
// @import '~@fullcalendar/bootstrap/main.css';

// @import '~codemirror/lib/codemirror.css';

//== Bootstrap
@import './app/shared/styles/bootstrap.scss';
//== Application
@import './app/shared/styles/app.scss';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Custom fonts
@font-face {
  font-family: 'poppins-bold';
  src: url(assets/fonts/POPPINS-BOLD.OTF);
}

@font-face {
  font-family: 'poppins-light';
  src: url(assets/fonts/POPPINS-EXTRALIGHT.OTF);
}

@font-face {
  font-family: 'poppins-reg';
  src: url(assets/fonts/POPPINS-REGULAR.OTF);
}

@font-face {
  font-family: 'poppins-thin';
  src: url(assets/fonts/POPPINS-THIN.OTF);
}

// Font-family for whole application except font-awesome icons and mat-icons
* :not(.fa, .far, .fas, .fab, mat-icon) {
  font-family: 'poppins-reg' !important;
}

// Style for avoid font-family on input label and required icon (*)
.mat-form-field-label > mat-label {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
}

.mat-placeholder-required {
  font-family: unset !important;
}

// Mat-form-field and it's child styles
mat-form-field {
  width: 100%;
  //   line-height: normal !important;
  // mat-label:not(.mat-form-field-label > mat-label),
  // input {
  //   font-size: 16px !important;
  // }
  // mat-error {
  //   font-size: 12px;
  // }
}

// Mat-table style
.mat-table {
  width: 100% !important;
}

// Mat-table search bar style
.search-input {
  font-size: 14px;
  width: 15%;
  margin: 0px 8px;
}

// Overlay style for full screen laoder with backdrop
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.3 !important;
}

// For alignment of card in the exact center of the page
.first-user-widget {
  top: 8vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Custom style for material stroke button type (except disabled button)
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #07509d;
  color: #07509d;
}

// Style for each module parent container
.container {
  margin-top: 7em;
}

.fluid-container {
  padding: 0 1em 1em 1em;
}

// Style for each module heading, breadcrums and icon
.heading {
  display: flex;
  align-items: center;
  padding: 22px !important;

  .mat-card-header {
    align-items: center !important;

    .mat-card-title {
      margin-bottom: unset !important;
      display: flex !important;
    }
  }

  .folder-icon {
    height: 38px;
    width: 38px;
    font-size: 38px;
    color: $body-color;
  }

  .heading-title {
    margin-bottom: 0 !important;
    // margin-left: 20px;
    font-size: 22px;
    padding: 8px;
    font-weight: 500;
  }

  .heading-with-breadcrumb {
    margin-bottom: 0 !important;
    // margin-left: 20px;
    font-size: 22px;
    padding: 8px;
    font-weight: unset;
    color: $dark;
  }

  .heading-with-breadcrumb:hover {
    cursor: pointer;
    background-color: $gray-lighter;
    color: inherit;
    border-radius: 6px;
  }

  .breadcrumb-name {
    margin-bottom: 0 !important;
    font-size: 22px;
    padding: 8px;
    font-weight: 500;
  }
}

// Style for progress spinner
.spinner {
  position: absolute !important;
  left: 48%;
  top: 45%;
}

// Style for delete button in actions colums of any table
.action-buttons {
  .delete-button:hover {
    // color: $warning !important;
  }
}

// Style for icon in table before the name, at each row
.icons {
  font-size: 25px;
  color: #08509d;
}

// Style for checkbox group
.checkbox-group {
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 15px 0;

  .mat-checkbox {
    margin: 0 5px;
  }
}

// Style for radio button group
.radio-group {
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 15px 0;

  .mat-radio-button {
    margin: 0 5px;
  }
}

// Style for sweetalert
.custom-alert {
  .swal-text {
    text-align: center;
  }
}

// Style for tooltip font size
.mat-tooltip {
  font-size: 13px !important;
}

// Style for carousel navigation icons
.carousel-control-prev-icon {
  background-image: url('./assets/img/icons/left-chevron.png') !important;
}

.carousel-control-next-icon {
  background-image: url('./assets/img/icons/right-chevron.png') !important;
}

/* Scrollbar settings */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9 !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal-overlay {
  // background-color: rgb(255, 142, 26, 0.40);
  // background-color: rgb(255, 167, 77, 0.4);
}

.swal-text {
  text-align: center !important;
  line-height: 20px !important;
  margin-top: 20px !important;
}

.pro-restrict {
  cursor: not-allowed !important;
}

.pro-restrict:hover {
  box-shadow: none !important;
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

.pro-flag {
  color: orange;
  position: absolute;
  right: 23px;
  z-index: 1;
  top: 11px;
  font-size: medium;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
}

.enterprise_text_label {
  color: orange;
  font-size: x-small;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
}

.mat-badge-content {
  font-family: 'Material Icons' !important;
  background-color: transparent;
  font-size: large;
}

.disable-Btn {
  cursor: not-allowed !important;
}

.feedback-btn-container {
  position: fixed !important;
  top: 50% !important;
  right: -25px !important;
  height: auto !important;
  z-index: 1000 !important;
  transform: rotate(270deg) !important;
}

.feedback-btn {
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: white;
  background-color: #07509d;
  border-style: none;
  padding: 12px;
  font-size: 16px;
}

.feedback-btn:hover {
  background-color: #05376c;
}

// Snackar custom css for pannel
.custom-snackbar-pannel {
  // background-color: lightskyblue;
  // background-color: peachpuff;
}

.sd-root-modern {
  -webkit-font-smoothing: antialiased;
  --sd-mobile-width: 600px;
  --sd-imagepicker-gap: 16px;
  width: 100%;
  font-family: 'poppins-reg' !important;
  font-size: 0.5rem;
  padding: none;
  background-color: var(--background-dim, white);
}

.sd-btn {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  /* user-select: none; */
  cursor: pointer;
  /* outline: none; */
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.sd-body__navigation .sd-btn {
  padding: 0 16px;
}

.svc-logic-question-value,
.sd-element--with-frame:not(.sd-element--collapsed) {
  border-radius: 0;
  box-sizing: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background: white;
  box-shadow: none;
}

.sv-action {
  margin-left: 83%;
}

.sd-root-modern,
.svc-logic-question-value {
  --sd-base-padding: 0px;
  --sd-base-vertical-padding: 0px;
  --sd-page-vertical-padding: 0px;
}

.mat-icon-sm {
  font-size: 18px;
  height: 18px !important;
  width: 18px !important;
}

// .sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
//   background-color: #05376c;
//   border-color: #05376c;
//   color: white;
// }
